
























import { Component, Prop, Vue } from 'vue-property-decorator';
// internal dependencies
// child components
import { TransactionView } from '@/core/transactions/TransactionView';
// @ts-ignore
import TransactionDetailRow from '@/components/TransactionDetails/TransactionDetailRow/TransactionDetailRow.vue';
import { Transaction } from 'symbol-sdk';

@Component({ components: { TransactionDetailRow } })
export default class DetailView<T extends Transaction> extends Vue {
    @Prop({ default: null }) view: TransactionView<T>;
}
