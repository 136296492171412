





































// external dependencies
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

// child components
// @ts-ignore
import MosaicAmountDisplay from '@/components/MosaicAmountDisplay/MosaicAmountDisplay.vue';
// @ts-ignore
import AddressDisplay from '@/components/AddressDisplay/AddressDisplay.vue';
// @ts-ignore
import PaidFeeDisplay from '@/components/PaidFeeDisplay/PaidFeeDisplay.vue';
// @ts-ignore
import MessageDisplay from '@/components/MessageDisplay/MessageDisplay.vue';
import { TransactionDetailItem } from '@/core/transactions/TransactionDetailItem';
import { NetworkType } from 'symbol-sdk';
import { networkConfig } from '@/config';

@Component({
    components: { MosaicAmountDisplay, AddressDisplay, PaidFeeDisplay, MessageDisplay },
    computed: mapGetters({ networkType: 'network/networkType' }),
})
export default class TransactionDetailRow extends Vue {
    @Prop({ required: true }) item: TransactionDetailItem;
    private networkType: NetworkType;
    private get label(): string {
        return (this.item && this.item.key) || '';
    }
    /**
     * Returns the explorer url
     */
    public get explorerUrl() {
        return networkConfig[this.networkType].explorerUrl.replace(/\/+$/, '') + '/transactions/' + this.item.value;
    }
}
