

















import { AddressDisplayTs } from './AddressDisplayTs';
import './AddressDisplay.less';

export default class AddressDisplay extends AddressDisplayTs {}
