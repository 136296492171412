










import { TransactionDetailsHeaderTs } from './TransactionDetailsHeaderTs';

export default class TransactionDetailsHeader extends TransactionDetailsHeaderTs {}
