























import { MessageDisplayTs } from './MessageDisplayTs';

export default class MessageDisplay extends MessageDisplayTs {}
