





















import { TransactionDetailsTs } from './TransactionDetailsTs';
export default class TransactionDetails extends TransactionDetailsTs {}
