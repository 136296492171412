
























import { PaidFeeDisplayTs } from './PaidFeeDisplayTs';

export default class PaidFeeDisplay extends PaidFeeDisplayTs {}
